<template>
  <div class="box">
    <div class="item" v-for="item of menus" :key="item.path" @click="hanldeItemClick(item)">
      <img :src="item.icon" />
      <span class="title">{{ item.name }}</span>
    </div>
  </div>
</template>
<script setup>
import { inject } from 'vue';

let { router } = inject('ctx');
const menus = [
  // {
  //   name: '申请报修',
  //   path: '/repair/apply',
  //   icon: require('@/assets/image/repair.png')
  // },
  {
    name: '建议投诉',
    path: '/complaint/index',
    icon: require('@/assets/image/toushu1.png')
  }
];
const hanldeItemClick = (item) => {
  router.push({
    path: item.path
  });
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  padding: 48px 56px 0 56px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 116px;
    img {
      width: 100%;
    }
    .title {
      margin-top: 16px;
      font-size: 28px;
    }
  }
}
</style>
